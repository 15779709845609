import { admin2 } from '@/api/instance'

// => 建立獎項
export const CreateReward = async ({
  shopId,
  lotteryId,
  ImageId,
  name,
  availableStock,
  showAvailableStock,
  unlimitedStock,
  newProbability,
  award,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/lotteryAward/${lotteryId}`,
    data: {
      ImageId,
      name,
      availableStock,
      showAvailableStock,
      unlimitedStock,
      newProbability,
      award,
    },
  })
  return res
}

// => 取得獎項
export const GetReward = async ({ shopId, start, limit, lotteryId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/lotteryAward/${lotteryId}`,
    params: { start, limit },
  })
  return res
}

// => 取得獎項總數
export const GetRewardCount = async ({ shopId, lotteryId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/lotteryAward/${lotteryId}/count`,
  })
  return res
}

// => 查找獎項總數
export const FindReward = async ({ shopId, lotteryId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/lotteryAward/${lotteryId}/${id}`,
  })
  return res
}

// => 更新抽獎活動獎項
export const UpdateReward = async ({
  shopId,
  lotteryId,
  id,
  ImageId,
  name,
  availableStock,
  showAvailableStock,
  unlimitedStock,
  newProbability,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/lotteryAward/${lotteryId}/${id}`,
    data: {
      ImageId,
      name,
      availableStock,
      showAvailableStock,
      unlimitedStock,
      newProbability,
    },
  })
  return res
}

// => 查找獎項總數
export const DeleteReward = async ({ shopId, lotteryId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/lotteryAward/${lotteryId}/${id}`,
  })
  return res
}

// 批次更新抽獎活動獎項機率
export const BulkUpdateProbability = async ({ shopId, lotteryId, data }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/lotteryAward/${lotteryId}/bulkUpdateProbability`,
    data,
  })
  return res
}

// 計算活動中獎機率
export const GetAwardProbability = async ({ shopId, lotteryId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/lottery/${lotteryId}/getAwardProbability`,
  })
  return res
}

// 預覽獎項中獎機率
export const PreviewAwardProbability = async ({ shopId, lotteryId, data }) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/lottery/${lotteryId}/previewAwardProbability`,
    data,
  })
  return res
}
